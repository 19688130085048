import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'

import Seo from './../components/Seo'
import RelatedItems from './../components/RelatedItems'
import SectionProvider from '../components/Layout/SectionProvider'

const Header = styled.div`
    width: 100%;

    padding: 0 0 ${props => props.theme.space.paddingMobile} 0;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: 75vw;

        padding-top: 11vw;
    }
`

const Subtitle = styled.p` 
    display: none;
    
    text-align: left;

    opacity: 0.35;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        display: block;

        margin-bottom: ${props => props.theme.desktopVW(25)};

        text-align: right;
    }
`

const FeaturedImage = styled(Img)`
    width: 100%;
`

const Portfolio = ({ 
    data: {
        contentfulItemPortfolio: {
            name,
            subtitle,
            image,
            sections,
            related,
        },
        allContentfulItemPortfolio
    }
}) => {
    return (
        <>
            <Seo
                title={name}
            />
            <Header>
                <ScrollAnimation animateIn='fadeIn' duration={1.5}>
                    <Subtitle>{subtitle}</Subtitle>
                    {image && image.fluid && <FeaturedImage fluid={image.fluid} />}
                </ScrollAnimation>
            </Header>
            {sections && <SectionProvider sections={sections} />}
            {related && <RelatedItems title={related.title} backgroundColor={related.backgroundColor} data={allContentfulItemPortfolio} prefix='portfolio' />}
        </>
    )
}

export default Portfolio

export const query = graphql`
	query($id: String!) {
		contentfulItemPortfolio(id: {eq: $id}) {
            name
            subtitle
            image {
                fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
            sections {
                __typename ... on Node {
                    ... on ContentfulSectionBanner {
                        ...sectionBanner
                    }
                    ... on ContentfulSectionHeroBanner {
                        ...sectionHeroBanner
                    }
                    ... on ContentfulSectionRow {
                        ...sectionRow
                    }
                }
            }
            related {
                title {
                    json
                }
                backgroundColor {
                    color
                }
            }
        }
        allContentfulItemPortfolio(
            filter: {id: {ne: $id}},
            limit: 3
        ) {
            edges {
                node {
                    name
                    slug
                    thumbnail {
                        fluid(maxWidth: 600, quality: 100) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
	}
`